<template>
  <div>
    <template v-if="!isLoginSuccessful">
      <aForm class="login-form" :form="form" @submit="handleSubmit">
        <aFormItem class="mb-10">
          <aInput
            class="travel-input"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: true,
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="Insira seu e-mail"
          >
            <aIcon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </aInput>
        </aFormItem>

        <aFormItem class="mb-10">
          <aInputPassword
            class="travel-input"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Escreva sua senha.',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Insira sua senha"
          >
            <aIcon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </aInputPassword>
        </aFormItem>

        <aFormItem class="mb-0">
          <aButton
            type="primary"
            size="large"
            html-type="submit"
            style="width: 100%"
            class="mt-10"
            :loading="loading"
          >
            Login
          </aButton>
        </aFormItem>
      </aForm>
    </template>
    <template v-if="isLoginSuccessful && enable2FA">
      <AuthenticatorForm
        :twoFactorsIsEnabled="twoFactorsIsEnabled"
        :userEmail="userEmail"
        :isLoginPage="false"
        :updateStorageData="updateStorageData"
        :enable2FA="enable2FA"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </template>
  </div>
</template>

<script>
import AuthenticatorForm from "./AuthenticatorForm.vue";

export default {
  name: "LoginFormModal",
  components: { AuthenticatorForm },
  props: {
    updateStorageData: Boolean,
    enable2FA: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      isLoginSuccessful: false,
      twoFactorsIsEnabled: false,
      userEmail: "",
    };
  },

  methods: {
    verificationSuccessfully(verificationData) {
      this.$emit("closeDisconnectedLoginModal");
      this.$emit("verificationSuccessfully", verificationData);
    },
    userAuthentication(userData) {
      this.$hayaApi
        .post("/login", userData)
        .then(({ data }) => {
          this.isLoginSuccessful = true;
          this.userEmail = userData.email;
          this.twoFactorsIsEnabled = data.twoFactorsIsEnabled;

          if (!this.enable2FA) this.$emit("verificationSuccessfully");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          this.userAuthentication(values);
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
